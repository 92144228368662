<template>
  <el-menu
    :default-active="$route.path"
    background-color="#2a47b0"
    text-color="#fff"
    active-text-color="#2A47B0"
    router
    unique-opened
  >
    <MenuItem :list="routes" />
  </el-menu>
</template>

<script>
import { mapState } from 'vuex'
import MenuItem from './menu_item.vue'

export default {
  components: { MenuItem },
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapState({ routes: (state) => state.routes.allowRoutes })
  }
}
</script>

<style lang='scss' scoped>
</style>
