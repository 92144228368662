<template>
  <el-container>
    <el-aside class="sidebar" width="200px">
      <LayoutAside />
    </el-aside>
    <el-container>
      <el-header class="header" style="padding: 0">
        <LayoutHeader />
      </el-header>
      <el-main class="body">
        <TagView />
        <div class="scroll_view">
          <el-scrollbar style="width: 100%; height: 100%">
            <div class="cache_box">
              <transition name="fade-transform" mode="out-in">
                <keep-alive :include="cacheViews">
                  <router-view></router-view>
                </keep-alive>
              </transition>
            </div>
          </el-scrollbar>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import LayoutHeader from "./layout_header.vue";
import LayoutAside from "./layout_aside.vue";
import TagView from "../tagView/tag_view";
import { mapState } from "vuex";

export default {
  components: { LayoutHeader, LayoutAside, TagView },
  data() {
    return {};
  },
  methods: {
    // 添加路由
    addView() {
      this.$store.dispatch("tagViews/addView", this.$route);
    },
  },
  mounted() {
    this.addView();
  },
  computed: {
    ...mapState({
      cacheViews: (state) => state.tagViews.cacheView,
    }),
  },
  watch: {
    $route() {
      this.addView();
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  background-color: $theme;
  height: 100vh;
}

.header {
  height: 64px;
}

.body {
  padding: 0px;
}

.scroll_view {
  height: calc(100vh - 104px);
}

.cache_box {
  width: 100%;
  height: 100%;
}
</style>
<style lang="scss">
.scroll_view {
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .el-scrollbar__bar.is-horizontal {
    display: none;
  }
}

/* fade-transform */
@keyframes router_fade_in {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes router_fade_out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(30px);
  }
}

.fade-transform-enter-active {
  animation: router_fade_in 0.28s linear;
}

.fade-transform-leave-active {
  animation: router_fade_out 0.28s linear;
}
</style>
