<template>
  <div class="header">
    <div class="controller">
      <div class="wel">你好，{{ remark || '管理员' }}</div>
      <router-link to="/edit_password" class="avator">
        <img src="../../assets/avator.png" title="修改密码" />
      </router-link>

      <div class="logout">
        <img
          src="../../assets/header_logout.png"
          title="退出"
          @click="logout"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {
    // 登出
    async logout() {
      const result = await this.$confirm('您确定要退出登录该系统吗 ?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(() => 'cancel')
      if (result === 'cancel') return

      this.$store.dispatch('user/logout').then(() => {
        this.$router.push('/login')
      })
    }
  },
  computed: {
    remark() {
      return this.$store.getters.remark
    }
  }
}
</script>

<style lang='scss' scoped>
.header {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  background-color: #ffffff;

  .controller {
    height: 100%;
    display: flex;
    align-items: center;

    .wel {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1b2351;
    }

    .avator {
      width: 38px;
      height: 38px;
      margin-left: 8px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .logout {
      width: 28px;
      height: 28px;
      margin: 0 36px 0 39px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
