import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 导入全局样式表
import './styles/global.scss'
// 导入字体图标库
import './assets/fonts/iconfont.css'
// 导入自定义指令
import './directives'
// 导入纳米级进度条
import 'nprogress/nprogress.css'
// 导入进度条样式
import 'element-ui/lib/theme-chalk/index.css'
// 导入elementui的样式
import './styles/theme/index.css'
// 导入elementUI的按需引入
import '@/utils/elementui.js'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

Vue.component(CollapseTransition.name, CollapseTransition)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
