import axios from 'axios'

import { Message } from 'element-ui'

import NProgress from 'nprogress'

// 测试服务器
// axios.defaults.baseURL = 'https://test.ssmanage.com'
// 线上测试服务器
// axios.defaults.baseURL = 'https://online.ssmanage.com'
// 线上服务器
axios.defaults.baseURL = 'https://api.ssmanage.com'

axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.defaults.timeout = 15000

axios.interceptors.request.use(config => {
  NProgress.start()
  return config
})

axios.interceptors.response.use(config => {
  NProgress.done()
  return config.data
})

export default function ({ url, method = 'get', data }) {
  const token = sessionStorage.getItem('token')
  axios.defaults.headers.Authorization = token || ''
  const query = {
    url,
    method
  }
  if (method.toLowerCase() === 'get') {
    query.params = data
  } else {
    query.data = data
  }

  return new Promise((resolve, reject) => {
    axios(query)
      .then(success => {
        resolve(success)
      })
      .catch(fail => {
        NProgress.done()
        Message.error('网络一不小心走丢了~')
        resolve({
          ret: -1,
          data: {},
          msg: '网络一不小心走丢了~'
        })
      })
  })
}
