export default {
  namespaced: true,
  state: () => ({
    // 允许访问的路由
    allowRoutes: [],
    // 拥有的侧边栏ID
    has: []
  }),
  mutations: {
    // 添加可访问的路由
    addAllowRoutes(state, routes) {
      state.allowRoutes = routes
    },

    // 保存拥有的侧边栏id
    saveHas(state, has) {
      state.has = has
    }
  },
  actions: {
    // 通过用户信息里面的 权限, 过滤出可访问的路由
    getAllowRoutes({ commit, dispatch }, { userInfo, routes }) {
      const role = userInfo.role
      let has = []
      if (userInfo.project_menu) {
        try {
          const obj = JSON.parse(userInfo.project_menu)
          has = [...obj.has]
        } catch (Exception) {
          has = []
        }
      }
      commit('saveHas', has)
      return new Promise((resolve, reject) => {
        if (role === 100) {
          commit('addAllowRoutes', routes)
          // 这里应该返回 可访问的路由列表 并没有做处理
          resolve(routes)
        } else {
          dispatch('filterRoutes', routes).then(resultRoute => {
            commit('addAllowRoutes', resultRoute)
            // 这里应该返回 可访问的路由列表 并没有做处理
            resolve(resultRoute)
          })
        }
      })
    },

    // 过滤可访问的路由
    filterRoutes({ state }, routes) {
      return new Promise((resolve, reject) => {
        const result = []
        const has = state.has
        routes.map(i => {
          /**
           * 这里做的比较简单, 因为知道菜单最多只有两级, 所以没有做什么特殊处理
           * 直接一次循环搞定所有
           */
          const children = []
          i.children.map(j => {
            if (has.includes(j.meta.id)) {
              children.push(j)
            }
          })
          if (children.length) {
            i.children = children
            result.push(i)
          }
        })
        resolve(result)
      })
    }
  }
}
