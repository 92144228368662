<template>
  <div>
    <template v-for="item in list">
      <!-- 子元素需要递归的 渲染这个递归元素 -->
      <el-submenu
        :index="item.children[0].path"
        v-if="hasMoreOneChild(item) || childChildren(item)"
        :key="item.path"
      >
        <template slot="title">
          <i
            :class="(item.meta && item.meta.icon) || ''"
            class="menu_item_icon"
          ></i>
          <span>{{ item.meta.name || 'no-name' }}</span>
        </template>

        <MenuItem :list="item.children" />
      </el-submenu>

      <!-- 没有子元素 或者子元素只有一个, 且子元素的一个的子元素的childre没有或者只有一个 -->
      <el-menu-item
        :index="item.children && item.children[0] ? item.children[0].path : item.path
          "
        v-else
        :key="item.path"
      >
        <i
          :class="(item.meta && item.meta.icon) || ''"
          class="menu_item_icon"
        ></i>
        <span slot="title">{{
          item.children && item.children[0]
          ? item.children[0].meta.name
          : item.meta.name
        }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    list: Array
  },
  data() {
    return {}
  },
  methods: {
    // 判断item是否不止一个子节点
    // 有子节点, 且子节点的个数大于零的时候, 需要调用递归
    hasMoreOneChild(item) {
      if (item.children) {
        const show = item.children.filter(i => {
          return !i.hidden
        })
        return show.length > 1
      } else {
        return false
      }
    },

    // 当节点的  子元素  的子元素的  children 大于零时, 也需要递归渲染
    childChildren(item) {
      if (
        item.children &&
        item.children[0] &&
        item.children[0].children &&
        item.children[0].children.length > 0
      ) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang='scss'>
.el-menu {
  .menu_item_icon {
    color: #fff;
    margin-right: 16px;
    font-size: 20px;
  }

  .is-active {
    background: #fff !important;
  }

  .el-icon-arrow-down:before {
    color: #fff;
  }
}
</style>
