<template>
  <div
    class="tag_box"
    id="tagViewEl"
  >
    <div class="tag_view_content">
      <div
        class="tag_view"
        @mousewheel.stop.prevent="mouseScroll"
        :style="'transform: translateX(' + translateX + 'px)'"
      >
        <router-link
          :to="item.fullPath"
          v-for="item in tagViews"
          :key="item.path"
          :class="$route.path === item.path ? 'current' : ''"
        >
          <div class="radius radius_left">
            <div></div>
          </div>
          <span>{{ item.meta.name }}</span>
          <div class="close_icon">
            <i
              class="el-icon-close"
              @click.stop.prevent="delCurrentView(item)"
            ></i>
          </div>
          <div class="radius radius_right">
            <div></div>
          </div>
        </router-link>
      </div>
      <!-- <ul class="right_menu" v-if="rightMenu">
      <li>关闭当前</li>
      <li>关闭全部</li>
    </ul> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 是否显示右键菜单
      // rightMenu: false
      // 位移
      translateX: 0
    }
  },
  methods: {
    // 关闭当前的tagView
    delCurrentView(item) {
      if (item.path === '/welcome') return
      this.$store
        .dispatch('tagViews/delCurrentView', item)
        .then(({ tagViews }) => {
          if (item.fullPath === this.$route.fullPath) {
            // 关闭的是当前高亮的标签
            if (tagViews.length) {
              const path = tagViews[tagViews.length - 1].fullPath
              this.$router.push(path)
            } else {
              this.$router.push('/welcome')
            }
          }
          // 这里会对页面进行重新编排
          // 因为页面宽度需要重新计算, 否则滚动区域的位置会出现偏移
          this.pageResizeHandle()
        })
    },

    // 鼠标中间滚动
    mouseScroll(ev) {
      const w = 110 * this.tagViews.length
      const max = document.querySelector('#tagViewEl').offsetWidth
      const movable = w - max
      if (movable < 0) return
      if (ev.deltaY < 0) {
        if (this.translateX + 10 > 0) {
          this.translateX = 0
        } else {
          this.translateX += 10
        }
      } else {
        if (Math.abs(this.translateX - 10) > movable) {
          this.translateX = -movable
        } else {
          this.translateX -= 10
        }
      }
    },

    // 监听页面的滚动
    resize(flag) {
      if (flag) {
        window.addEventListener('resize', this.pageResizeHandle)
      } else {
        window.removeEventListener('resize', this.pageResizeHandle)
      }
    },

    // 页面尺寸变更事件
    pageResizeHandle() {
      this.translateX = 0
    }
  },
  computed: {
    tagViews() {
      return this.$store.state.tagViews.tagViews
    }
  },
  created() {
    this.resize(true)
  },
  destroyed() {
    this.resize(false)
  }
}
</script>

<style lang='scss' scoped>
.tag_box {
  position: relative;

  .tag_view_content {
    width: 100%;
    overflow: hidden;

    .tag_view {
      position: relative;
      height: 40px;
      background-color: #eff2f7;
      border-bottom: 2px solid #fff;
      display: flex;
      padding: 0 2px;

      a {
        position: relative;
        height: 100%;
        flex: 0 0 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
        background-color: #eff2f7;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;

        .radius {
          display: none;
          position: absolute;
          bottom: 0;
          z-index: 1;
          width: 10px;
          height: 10px;
          background-color: #fff;

          div {
            width: 100%;
            height: 100%;
            background-color: #eff2f7;
          }
        }

        .radius_left {
          left: -10px;

          div {
            border-radius: 0 0 5px 0;
          }
        }

        .radius_right {
          right: -10px;

          div {
            border-radius: 0 0 0 5px;
          }
        }

        &.current {
          position: relative;
          background-color: #fff;
          color: $theme;

          .radius {
            display: block;
          }
        }

        &:hover .close_icon {
          background-color: rgba(42, 71, 176, 0.6);

          i {
            color: #fff;
          }
        }

        .close_icon {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          margin-left: 6px;
          transition: all 0.3s;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            font-size: 12px;
            color: $theme;
          }
        }
      }
    }

    .right_menu {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: #fff;
      padding: 6px 0;
      border-radius: $radius;
      box-shadow: 2px 4px 8px 4px rgba(0, 0, 0, 0.15);

      li {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        cursor: pointer;
        padding: 0 16px;

        &:hover {
          background-color: #eeeeee;
        }
      }
    }
  }
}
</style>
