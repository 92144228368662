import Vue from 'vue'
import Store from '../store'

// 超级管理员可见
Vue.directive('supAdmin', {
  inserted(el) {
    const role = Store.getters.role
    if (role !== 100) {
      el.style = 'display: none!important'
    }
  }
})

// 运营商可见
Vue.directive('operator', {
  inserted(el) {
    const role = Store.getters.role
    if (role === 100) {
      el.style = 'display: none!important'
    }
  }
})
