import { GetUserInfo } from '@/api'

export default {
  namespaced: true,
  state: () => ({
    // 当前登录的用户信息
    userInfo: '',
    // 当前用户拥有的权限列表
    has: [],
    // 用户权限
    role: ''
  }),
  mutations: {
    // 通用方法
    save(state, kw) {
      const [key, val] = kw
      state[key] = val
    },
    // 保存用户信息
    saveUserInfo(state, userInfo) {
      state.userInfo = userInfo
      if (userInfo.project_menu) {
        const obj = JSON.parse(userInfo.project_menu)
        state.has = obj.has
      }
    }
  },
  actions: {
    // 获取用户信息
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        GetUserInfo()
          .then(res => {
            if (res.ret === 0) {
              commit('saveUserInfo', res.data)
              commit('save', ['role', res.data.role])
            }
            resolve(res)
          })
          .catch(() => {
            reject(
              new Error({
                ret: -1
              })
            )
          })
      })
    },

    // 缓存token
    saveToken({ commit }, token) {
      window.sessionStorage.setItem('token', token)
    },

    // 退出登录
    logout({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        // 清除token
        window.sessionStorage.clear()
        // 清除用户信息
        commit('saveUserInfo', '')
        // 清除可访问的路由(侧边栏)
        commit('routes/addAllowRoutes', [], { root: true })
        // 清除访问过tagView 和 缓存的 TagView
        commit('tagViews/delAllView', '', { root: true })
        // 重置路由
        resolve()
      })
    }
  }
}
