import Vue from 'vue'
import Vuex from 'vuex'

import routes from './modules/routes'
import tagViews from './modules/tagViews'
import user from './modules/user'
import device from './modules/device.js'
import getters from './getters.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters,
  modules: {
    routes,
    tagViews,
    user,
    device
  }
})
