import Vue from 'vue'
import VueRouter from 'vue-router'

// 异步路由
import syncRoutes from './modules/syncRoutes'

// vuex
import Store from '@/store'

// 布局组件
const Layout = () => import('@/components/layout/index.vue')

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/login'
  },

  {
    path: '/login',
    component: () => import('@/views/login.vue')
  },

  {
    path: '/welcome',
    component: Layout,
    children: [
      {
        path: '/welcome',
        name: 'welcome',
        component: () => import('@/views/welcome/welcome.vue'),
        meta: {
          name: '欢迎~'
        }
      }
    ]
  },

  {
    path: '/edit_password',
    component: Layout,
    children: [
      {
        path: '/edit_password',
        name: 'edit_password',
        component: () => import('@/views/edit_password/edit_password.vue'),
        meta: {
          name: '修改密码'
        }
      }
    ]
  }
]

const notFound = [
  {
    path: '/*',
    component: () => import('@/views/404/404.vue')
  }
]

const createRouter = () =>
  new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0
    }),
    routes
  })

const router = createRouter()

// 路由白名单
const whiteList = ['/', '/login']

// 重置路由
const resetRouter = () => {
  router.matcher = createRouter().matcher
}
// 测试用
// let isAddRouter = false
router.beforeEach(async (to, from, next) => {
  // document.title = to.meta.name || '极风管理系统'
  /**
   * 1. 判断跳转路径, 如果是去 / 或者 /login 直接放行
   * 2. 判断是否有 token 如果没有的话 重定向到登录
   * 3. 判断是否有用户信息, 如果没有的话, 获取用户信息
   * 4. 判断是否有可访问的路由, 如果没有的话, 需要根据用户信息处理路由, 处理后
   *    1. 需要把处理完的路由 存在 store 中用来渲染侧边栏
   *    2. 需要把处理完的路由 添加到 routes 中
   * 5. 放行
   *
   * 本地有 token  有用户信息 有可访问的路由 证明登录了
   */
  if (!whiteList.includes(to.fullPath)) {
    // 不在路由白名单
    const token = sessionStorage.getItem('token')
    if (token) {
      // 有 token 判断是否有用户信息
      const userInfo = Store.state.user.userInfo
      if (!userInfo) {
        // 获取用户信息 处理用户可访问路由, 放行
        const { ret, data, msg } = await Store.dispatch('user/getUserInfo')
        if (ret !== 0) {
          return next('/login')
        }
        // 获取到用户信息 data, 处理可访问的路由
        const allowRoutes = await Store.dispatch('routes/getAllowRoutes', {
          userInfo: data,
          routes: syncRoutes
        })
        resetRouter()
        router.addRoutes(allowRoutes.concat(notFound))
        next({ ...to, replace: true })
      } else {
        // 有用户信息, 直接放行
        next()
      }
    } else {
      // 没有 token, 说明没有登录
      next('/login')
    }
  } else {
    // 在路由白名单 直接放行
    next()
  }
})

export default router
