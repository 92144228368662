import { GetEnvTempApi, SetEnvTempApi } from '@/api'
import { Message } from 'element-ui'

export default {
  namespaced: true,
  state: () => ({
    // 设备号
    id_device: '',
    // 设备类型 1蓝牙
    deviceType: '',
    // 项目id
    id_project: '',
    // 设备温度端口
    temp_index: ''
  }),
  mutations: {
    // 通用信息
    save(state, kw) {
      const [k, w] = kw
      state[k] = w
    },

    saveDevice(state, device) {
      state.id_device = device
    },
    saveProject(state, id) {
      state.id_project = id
    },
    // 保存用户信息
    savePortInfo(state, info) {
      state.temp_index = info.temp_index
    }
  },
  actions: {
    // 获取设备信息
    async getDeviceInfo({ commit, state }) {
      const params = {
        id_device: state.id_device
      }
      const { ret, data, msg } = await GetEnvTempApi(params)
      if (ret !== 0) {
        return Message.error(msg)
      }
      commit('savePortInfo', data)
    },

    // 设置设备
    async setDevice({ dispatch, commit, state }, info) {
      const params = {
        id_device: state.id_device,
        ...info
      }
      const { ret, data, msg } = await SetEnvTempApi(params)
      if (ret !== 0) {
        return Message.error(msg)
      }
      Message.success('设置成功')
      dispatch('getDeviceInfo')
    }
  }
}
